<template>
  <div>
    <div class="data_board" v-loading="loading">
      <div class="data_item">
        <div class="item_top">
          <span>本月订单总金额(元)</span>
          <el-tooltip class="item" effect="dark" content="支付时间在本月的订单总金额" placement="top">
            <img src="@/assets/images/order/info.svg" alt="">
          </el-tooltip>
        </div>
        <div class="number">
          <span class="tag">￥</span>
          <span>{{DistributorData.orderAmount}}</span>
        </div>
        <span style="color: #399CFF;">日均订单金额 ￥{{DistributorData.Average}}</span>
      </div>
      <div class="data_item">
        <div class="item_top">
          <span>本月下单客户(个)</span>
          <el-tooltip class="item" effect="dark" content="支付时间在本月的客户个数" placement="top">
            <img src="@/assets/images/order/info.svg" alt="">
          </el-tooltip>
        </div>
        <div class="number">
          <span>{{DistributorData.orderMember}}</span>
        </div>
        <span style="color: #7367CE;">今日下单客户 {{DistributorData.todayMember}}</span>
      </div>
      <div class="data_item">
        <div class="item_top">
          <span>本月订单数量(个)</span>
          <el-tooltip class="item" effect="dark" content="支付时间在本月的订单个数" placement="top">
            <img src="@/assets/images/order/info.svg" alt="">
          </el-tooltip>
        </div>
        <div class="number">
          <span>{{DistributorData.orderNumber}}</span>
        </div>
        <span style="color: #DD8F89;">今日订单数量 {{DistributorData.todayOrder}}</span>
      </div>
      <div class="data_item">
        <div class="item_top">
          <span>待我审核订单数量(个)</span>
          <el-tooltip class="item" effect="dark" content="当前待我审核的订单数量" placement="top">
            <img src="@/assets/images/order/info.svg" alt="">
          </el-tooltip>
        </div>
        <div class="number">
          <span>{{DistributorData.pendingNumber}}</span>
        </div>
        <span style="color: #7EBFD1;cursor: pointer;" @click="pageJump('/customer/customerOrder')">点击查看<i class="el-icon-arrow-right"></i></span>
      </div>
      <div class="data_item"   >
        <div class="item_top">
          <span>重点产品销售进度</span>
          <el-tooltip class="item" effect="dark" content="2024下半年重点产品销售进度" placement="top">
            <img src="@/assets/images/order/info.svg" alt="">
          </el-tooltip>
        </div>
        <div class="number">
          <span>{{DistributorData.KeynoteProductSchedule2025FirstHalf}}</span>
        </div>
         <div>
          <span style="color: #399CFF;">销售金额 {{DistributorData.keynoteProductSale2025FirstHalf}}</span>
          <span style="color: #7EBFD1;cursor: pointer;margin-left: 10px;" @click="pageJump('/customer/productSale')">点击查看<i class="el-icon-arrow-right"></i></span>
         </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CustomerIndex',
  data() {
    return {
      DistributorData: {},
       loading: false,
    };
  },
  created() {
    
  },
  mounted() {
    this.getDistributorIndex()
  },
  methods: {
    // 获取看板数据
    getDistributorIndex(){
    this.loading = true
      this.$api.pcDistributorIndex().then((res) => {
        if (res.data.code === 200) {
              this.loading = false
          this.DistributorData = res.data.data
        }
      });
    },
    // 页面跳转
    pageJump(url){
      this.$router.push(url)
    },
  }
};
</script>
<style lang="scss" scoped>
.data_board{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 15px 18px;
  background: #ffffff;
  border-radius: 2px;
 flex-wrap: wrap;
  .data_item{
    width: 240px;
    height: 115px;
    padding-left: 20px;
    box-sizing: border-box;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background: #EAF4FE;
    border-radius: 6px 6px 6px 6px;
    font-size: 14px;
    color: #999999;

    &:nth-of-type(1){
      background: url("~@/assets/images/order/bg1.png");
    }
    &:nth-of-type(2){
      background: url("~@/assets/images/order/bg3.png");
    }
    &:nth-of-type(3){
      background: url("~@/assets/images/order/bg2.png");
    }
    &:nth-of-type(4){
      background: url("~@/assets/images/order/bg4.png");
    }
    &:nth-of-type(5){
      background: url("~@/assets/images/order/bg4.png");
    }

    .item_top{
      font-size: 16px;
      display: flex;
      align-items: center;

      img{
        margin-left: 2px;
        margin-top: 4px;
        cursor: pointer;
      }
    }

    .number{
      font-size: 32px;
      color: #222222;
      margin-bottom: 12px;

      .tag{
        font-size: 18px;
      }
    }
  }
}
</style>